$slider-height: calc(100vw * 0.46875);
$slider-height-mob: calc(100vw * 0.95);

.home__banner {
	&-container {
		z-index: 5;
		position: relative;
	}

	&-block {
		width: 70%;
	}

	&-slide {
		display: flex !important;
		height: $slider-height;

		@include mobile {
			height: $slider-height-mob;
		}

		img {
			top: 0;
			left: 0;
			height: 100%;
			object-fit: cover;
			opacity: 0.2;
		}
	}

	&-title,
	&-text {
		width: 100%;
	}

	&-text {
		@include mobile {
			margin: 3vw 0 5vw;
		}
	}

	&-title {
		font-size: $px72;
		font-weight: $medium;

		@include mobile {
			font-size: $px72-mob;
		}
	}

	&-button {
		margin-top: 4vw;
		padding: 1.5vw 4vw;

		@include mobile {
			padding: 2.5vw 4.5vw;
		}

		a {
			padding: 1.5vw 2.5vw;

			@include mobile {
				padding: 0;
			}
		}
	}

	&-logo {
		top: 50%;
		left: 65%;
		width: 20vw;
		pointer-events: none;

		@include mobile {
			bottom: 0%;
			right: 0%;
			width: 30vw;
			top: unset;
			left: unset;
		}
	}

	.slick {
		&-slider {
			height: $slider-height;
			overflow: hidden;

			@include mobile {
				height: $slider-height-mob;
			}
		}

		&-slide {
			height: $slider-height;
			width: 100vw;

			@include mobile {
				height: $slider-height-mob;
			}

			& > div {
				width: 100%;
				height: 100%;
			}
		}

		&-dots {
			bottom: 2vw;

			li {
				margin: 0 0.25vw;

				button:before {
					font-size: 1.2vw;
				}
			}
		}
	}
}

.home__products {
	&-product {
		box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.15);
		margin-bottom: 3vw;

		@include mobile {
			margin-bottom: 8vw;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&--text {
			margin: 1vw 0 2vw;

			@include mobile {
				margin: 3vw 0 6vw;
			}
		}

		&--info {
			width: 50%;
			padding: 3vw 0;

			@include mobile {
				width: 66%;
				padding: 5vw 0 5vw 5vw;
			}
		}

		&--image {
			width: 20vw;
			height: 20vw;
			background-image: url("../../media/ellipse.png");
			background-size: 70% 70%;
			background-position: center center;
			background-repeat: no-repeat;

			img {
				width: 90%;

				@include mobile {
					width: 100%;
				}
			}

			@include mobile {
				width: 30vw;
				height: 30vw;
				background-size: 100% 100%;
				background-position: left 14vw top 0;
			}
		}
	}
}

.home__services {
	&-service {
		padding: 4vw 4.5vw 0;

		@mixin line {
			content: " ";
			width: 100%;
			height: 0.15vw;
			background-color: $blue-light;
			display: block;
			margin-top: 4vw;

			@include mobile {
				height: 0.5vw;
			}
		}

		@include mobile {
			padding: 5vw 4vw 0;

			&:not(:last-child)::before {
				@include line;
			}
		}

		&:not(:last-child)::after {
			@include line;

			@include mobile {
				display: none;
			}
		}

		&:last-child {
			padding-bottom: 4vw;
		}

		&--reversed {
			flex-direction: row-reverse;

			@include mobile {
				flex-direction: column-reverse;
			}
		}

		&--text {
			margin: 1vw 0 2vw;

			@include mobile {
				margin: 3vw 0 6vw;
			}
		}

		&--info {
			width: 45%;
			padding: 0;

			@include mobile {
				width: 100%;
			}
		}

		&--image {
			width: 45%;

			@include mobile {
				width: 100%;
				margin-bottom: 6vw;
			}
		}
	}

	&-block {
		box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.15);
	}
}

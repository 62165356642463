.home__about {
	&-block {
		margin-bottom: 4vw;

		&:last-child {
			margin-bottom: 0;
		}

		&--image {
			width: 25%;

			@include mobile {
				width: 30%;
			}

			img {
				width: 78%;
			}
		}

		&--info {
			width: 74%;

			&-div {
				width: 93%;

				@include mobile {
					width: 90%;
				}
			}

			&::before {
				content: " ";
				width: 0.15vw;
				background-color: $blue-light;
				display: flex;
				align-self: stretch;
			}
		}
	}
}

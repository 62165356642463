.home__helper {
	background-color: $aqua;

	&-container {
		padding: 4vw 0;

		@include mobile {
			padding: 10vw 0;
		}
	}

	&-info {
		width: 60%;

		@include mobile {
			width: 50%;
		}

		&--title {
			color: $white;
		}

		&--text {
			color: $white;
			margin: 1vw 0 2vw;
		}
	}

	&-image {
		width: 35%;

		@include mobile {
			width: 45%;

			img {
				margin-bottom: 8vw;
			}
		}
	}

	&-button {
		background-color: $white;
		color: $aqua;
	}
}

.home {
	width: 100vw;

	&__heading {
		margin: 6vw 0 5vw;

		@include mobile {
			margin: 12vw 0 9vw;
		}

		&::after {
			content: " ";
			display: block;
			width: 4vw;
			height: 0.15vw;
			background-color: $yellow;

			@include mobile {
				width: 10vw;
				height: 0.5vw;
				margin-top: 1vw;
			}
		}
	}
}

$black: rgba(0, 0, 0, 1);
// $aqua: rgba(0, 149, 217, 1);
$aqua: rgba(63, 87, 175, 1);
// $blue-deep: rgba(24, 66, 108, 1);
$blue-deep: rgba(0, 149, 217, 1);
$blue-light: rgba(174, 219, 242, 1);
$yellow: rgba(255, 210, 95, 1);
$white: rgba(255, 255, 255, 1);
$grey: rgba(196, 196, 196, 1);

$container: 70vw;
$container-mob: 90vw;

$px18: 1vw;
$px20: 1.1vw;
$px24: 1.3vw;
$px30: 1.6vw;
$px36: 1.9vw;
$px40: 2.1vw;
$px48: 2.5vw;
$px72: 4vw;

$px18-mob: 3vw;
$px20-mob: 3.1vw;
$px24-mob: 3.4vw;
$px30-mob: 3.8vw;
$px36-mob: 3.9vw;
$px40-mob: 4.4vw;
$px48-mob: 5.1vw;
$px72-mob: 6vw;

$regular: 400;
$medium: 500;
$bold: 700;

$roboto: "Roboto", sens-serif;
$quicksand: "Quicksand", sens-serif;

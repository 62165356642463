.popup {
	z-index: 2;

	&__block {
		width: 85vh;
		height: 85vh;
		padding: 3vw;
		background-image: url("../../media/protez.png");
		background-size: cover;
		background-color: $white;
		box-shadow: 0px 0px 200px 50px rgba(0, 0, 0, 0.5);

		@include mobile {
			width: 90vw;
			height: 90vw;
			padding: 6vw;
		}
	}

	&__title {
		font-size: $px48;
		font-weight: $bold;

		@include mobile {
			font-size: $px48-mob;
		}
	}

	&__content {
		display: flex;
		align-self: stretch;
		flex-wrap: wrap;

		&-top {
			width: 100%;
		}
	}

	&__logo {
		width: 45%;

		@include mobile {
			width: 70%;
		}
	}

	&__info {
		padding-bottom: 7vw;

		a {
			padding: 0;
		}

		p {
			font-size: $px36;
			margin: 4vw 0 2vw;

			@include mobile {
				font-size: $px36-mob;
				margin: 6vw 0 6vw;
			}
		}
	}

	&__close {
		cursor: pointer;
		padding: 1vw;

		@include mobile {
			width: 15%;
		}
	}
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: $roboto;
	font-size: $px24;
	overflow-x: hidden;
}

img {
	width: 100%;
}

a {
	text-decoration: none;
	width: 100%;
	padding: 1.5vw 0.5vw;
	color: inherit;
}

p {
	font-size: $px24;
	font-weight: $regular;
	color: $blue-deep;
	margin: 0.5vw 0;

	@include mobile {
		font-size: $px24-mob;
	}
}

h1 {
	font-size: $px72;
	font-weight: $medium;
	color: $aqua;
	margin: 0;

	@include mobile {
		font-size: $px72-mob;
	}
}

h2 {
	font-size: $px48;
	font-weight: $bold;
	color: $aqua;
	margin: 0;

	@include mobile {
		font-size: $px48-mob;
	}
}

h3 {
	font-size: $px40;
	font-weight: $regular;
	color: $aqua;
	margin: 0;

	@include mobile {
		font-size: $px40-mob;
	}
}

button {
	border-radius: 3vw;
	background-color: $aqua;
	font-weight: $medium;
	font-family: $roboto;
	font-size: $px24;
	color: $white;
	outline: none;
	border: none;
	padding: 1.5vw 2.5vw;
	cursor: pointer;

	@include mobile {
		font-size: $px24-mob;
		border-radius: 6vw;
		padding: 2.5vw 4.5vw;
	}
}

svg {
	display: block;
	width: 100%;
	height: 100%;
}

iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.h-full {
	height: 100%;
}

.w-full {
	width: 100%;
}

.wh-full,
.hw-full {
	width: 100%;
	height: 100%;
}

.overflow-x {
	overflow-x: hidden;
}

.overflow-y {
	overflow-y: hidden;
}

.overflow {
	overflow: hidden;
}

.inline {
	display: inline !important;
}

.hidden {
	display: none !important;
}

.desktop {
	@include mobile {
		display: none !important;
	}
}

.mobile {
	@include desktop {
		display: none !important;
	}
}

.desktop-hidden {
	@include desktop {
		display: none !important;
	}
}

.mobile-hidden {
	@include mobile {
		display: none !important;
	}
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
	top: 0;
	left: 0;
}

[class*="container"] {
	width: $container;

	@include mobile {
		width: $container-mob;
	}
}

.home__partners {
	&-container {
		padding-bottom: 8vw;
	}

	$slider-height: calc(70vw * 0.15);
	$slider-height-mob: calc(95vw * 0.4);

	&-slide {
		height: $slider-height;

		@include mobile {
			height: $slider-height-mob;
		}

		img {
			height: 100%;
			object-fit: contain;
		}
	}

	&-slider {
		width: 100%;
		position: relative;
	}

	.slick {
		&-slider {
			height: $slider-height;
			overflow: hidden;
			width: 85%;
			position: static;

			@include mobile {
				width: 69%;
				height: $slider-height-mob;
			}
		}

		&-slide {
			height: $slider-height;

			@include mobile {
				height: $slider-height-mob;
			}
		}

		&-arrow {
			width: 5vw;
			height: 5vw;
			padding: 1vw;
			top: calc((70vw * 0.15) / 2);

			@include mobile {
				width: 8vw;
				height: 12vw;
				top: unset;
				bottom: 25%;
			}

			&::before {
				content: "";
			}
		}

		&-prev {
			left: -1vw;
		}

		&-next {
			right: -1vw;

			img {
				transform: rotate(180deg);
			}
		}
	}
}

.home__contacts {
	background-color: $aqua;
	padding-bottom: 4vw;

	&-logo {
		margin-bottom: 2vw;

		img {
			width: 9vw;
			margin-right: 2vw;

			@include mobile {
				width: 25vw;
				margin-right: 12vw;
			}
		}

		p {
			font-size: $px30;
			font-weight: $bold;

			@include mobile {
				font-size: $px30-mob;
			}
		}
	}

	&-title {
		@include mobile {
			width: 50%;
		}
	}

	&-info {
		p {
			font-size: $px20;
			margin: 1vw 0;

			@include mobile {
				font-size: $px48-mob;
			}
		}

		&--phone,
		&--mail,
		&--address,
		&--hours,
		&--vk {
			img {
				width: 2.5vw;
				height: 3.5vw;
				margin-right: 1.5vw;
				object-fit: contain;

				@include mobile {
					width: 10.5vw;
					height: 20vw;
					margin-right: 4.5vw;
				}
			}
		}

		&--vk {
			a {
				padding: 0;
			}
		}

		&--phone {
			a {
				padding: 0;
			}
		}
	}

	&-block {
		background-color: $white;
		box-shadow: 4px 8px 30px rgba(0, 0, 0, 0.25);
	}

	&-data {
		width: 38%;
		padding: 2vw 3vw;

		@include mobile {
			width: 100%;
			padding: 8vw;
		}
	}

	&-map {
		width: 62%;

		@include mobile {
			width: 100%;
		}

		&--block {
			height: 32vw;

			@include mobile {
				height: 85vw;
			}
		}
	}

	.home__heading {
		color: $white;
	}
}

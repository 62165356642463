.header {
	min-height: 6vw;
	background-color: $aqua;
	color: $white;
	font-size: $px20;

	@include mobile {
		min-height: 16vw;
		font-size: $px20-mob;
	}

	&__logo {
		line-height: 0;

		img {
			height: 6vw;
		}

		@include mobile {
			font-size: $px40-mob;
		}
	}

	&__vk {
		line-height: 0;

		@include mobile {
			width: 10%;
		}
	}

	&__phone {
		width: 20%;

		@include mobile {
			width: 43%;
		}

		&-phones {
			width: 75%;

			a {
				padding: 0;
			}

			@include mobile {
				width: 28vw;
			}
		}
	}

	&__address {
		width: 23%;

		img {
			margin-right: 1vw;
		}

		@include mobile {
			width: 34%;
		}
	}

	&__mail {
		width: 20.5%;

		@include mobile {
			width: 10%;

			span {
				display: none;
			}
		}
	}

	&__phone,
	&__address,
	&__mail,
	&__vk {
		img {
			width: 2.5vw;
			height: 2.5vw;

			@include mobile {
				width: 7vw;
				height: 7vw;
				margin-right: 2.5vw;
			}
		}
	}
}
